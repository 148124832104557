import React, { useState } from "react";
import Container from "../../components/chat-channel/Container";
import Layout from "../../components/chat-channel/Layout";
import TitleAndMetaTags from "../../components/common/TitleAndHeader";
import { RequestForm } from "../../components/form";
import { RequestFormModal } from "../../components/modalform";
import { useMedia } from "use-media";

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";

import {
  InfoIcon,
  PriceFreeIcon,
  PriceProIcon,
  PricestadardIcon,
  PriceEnterpriseIcon,
  YesIcon,
  NoIcon,
  UpArrowIcon,
  DropdownBlack,
} from "../../components/common/Icons";

export default function PricingPage() {
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState(true);
  const [yearly, isYearly] = useState(false);

  const [plan, setPlan] = useState("");

  const [showThanksPage, setShowThanksPage] = useState(false);

  const [more, setMore] = useState(false);
  const [more1, setMore1] = useState(false);
  const [more2, setMore2] = useState(false);


  const isMobile = useMedia({ maxWidth: "520px" });

  return (
    <>
      <TitleAndMetaTags
        title="Conversational AI Pricing | Free Trial | Workativ Assistant Chatbot Pricing"
        description="Learn more about conversational ai pricing plans for workativ assistant. Best value for price conversational ai chatbot solution for workplace support. "
        keywords={["IT Helpdesk Chatbot", "HR Chatbot", "Service Desk Chatbot"]}
        ogTitle="Conversational AI Pricing | Free Trial | Workativ Assistant Chatbot Pricing"
        ogDescription="Learn more about conversational ai pricing plans for workativ assistant. Best value for price conversational ai chatbot solution for workplace support. "
      />
      <Container>
        <Layout backgroundColor={"bg_feature"} logoFor="ASSISTANT">
          <div>
            {/*Pricing Start*/}
            <section className="top_section pricing_page">
              <div className="container-fluid">
                <div className="row">
                  <div className="container">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <div className="main-slider-left">
                        <h1 className="dek_pricing_page">
                          Choose from our plans below and start <br></br>your
                          free trial now
                        </h1>
                        <h1 className="mob_pricing_page">
                          Choose from our plans below and start your free trial
                          now
                        </h1>
                        <p>Flexible. Scalable. Affordable.</p>
                        <div className="form_below_content ">
                          <span>Easy setup.</span>
                          <span>21 Day free trial.</span>
                          <span>No credit card. </span>
                          <span>Onboarding included.</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section className="pricing-columns pricing-section">
              <div className="container">
                {/* <div className="toogle_left">
                  <label
                    className={
                      yearly ? "toggler" : " toggler toggler--is-active monthly"
                    }
                    id="filt-monthly"
                  >
                    Bill Monthly
                  </label>
                  <div className="toggle">
                    <input
                      type="checkbox"
                      id="switcher"
                      className="check"
                      onChange={() => {
                        isYearly(!yearly);
                      }}
                      checked={yearly}
                    />
                    <b className="b switch" />
                  </div>
                  <label
                    className={
                      yearly ? "toggler toggler--is-active yearly" : "toggler "
                    }
                    id="filt-hourly"
                  >
                    Bill Yearly
                  </label>
                </div> */}
                <div className="assistant_pricing">
                  {!yearly ? (
                    <div className="monthly">
                      <div className="pricing-chart">
                        <div className="smaller-plans">
                          <div className="plan plan_child">
                            <div className="paln_svg_center">
                              <PriceFreeIcon />
                            </div>
                            <h4>Basic</h4>
                            <span className="users_upto">
                              (Suitable for 300 users or less)
                            </span>
                            <a
                              plan={plan}
                              onClick={() => setPlan("basic")}
                              className="button sign-up url_manipulation"
                              href="javascript:void(0);"
                            >
                              Get Free Trial
                            </a>
                            <ul>
                              {isMobile ? (
                                !more ? (
                                  <>
                                    <li>
                                      <label />
                                      Chatbot Builder
                                      <span tooltip="No-code chatbot builder tools to create and manage chatbot.">
                                        <InfoIcon />
                                      </span>
                                    </li>
                                    <li>
                                      <label />
                                      Chatbot Automation
                                      <span tooltip="No-code workflow builder to create app based workflow automations for chatbot.">
                                        <InfoIcon />
                                      </span>
                                    </li>
                                    <li>
                                      <label />
                                      App Integrations
                                      <span tooltip="Pre-built app integrations or connectors for chatbot.">
                                        <InfoIcon />
                                      </span>
                                    </li>
                                  </>
                                ) : (
                                  <>
                                    <li>
                                      <label />
                                      Chatbot Builder
                                      <span tooltip="No-code chatbot builder tools to create and manage chatbot.">
                                        <InfoIcon />
                                      </span>
                                    </li>
                                    <li>
                                      <label />
                                      Chatbot Automation
                                      <span tooltip="No-code workflow builder to create app based workflow automations for chatbot.">
                                        <InfoIcon />
                                      </span>
                                    </li>
                                    <li>
                                      <label />
                                      App Integrations
                                      <span tooltip="Pre-built app integrations or connectors for chatbot.">
                                        <InfoIcon />
                                      </span>
                                    </li>
                                    <li>
                                      <label />
                                      Workflow Marketplace
                                      <span tooltip="Pre-built app based workflows for chatbot.">
                                        <InfoIcon />
                                      </span>
                                    </li>
                                    <li>
                                      <label />
                                      Teams and Slack Integration
                                      <span tooltip="Deploy chatbot on your Slack and Teams.">
                                        <InfoIcon />
                                      </span>
                                    </li>

                                    <li>
                                      <label />
                                      <b>2 </b> Active Workflows
                                      <span tooltip="Total active workflow automations to use within chatbot. Admin can disable old and enable new workflows.">
                                        <InfoIcon />
                                      </span>
                                    </li>

                                    <li>
                                      <label />
                                      <b>600</b> Workflow Executions
                                      <span tooltip="Total number of workflow executions per month. ">
                                        <InfoIcon />
                                      </span>
                                    </li>
                                    <li>
                                      <label />
                                      <b>3000</b> Conversation Messages
                                      <span tooltip="Total number of conversation messages per month.">
                                        <InfoIcon />
                                      </span>
                                    </li>
                                    <li>
                                      <label />
                                      <b>1 </b>Admin
                                      <span tooltip="Number of admins access.">
                                        <InfoIcon />
                                      </span>
                                    </li>

                                    <li>
                                      <label />
                                      <b>$0.01</b> Additional Consumptions
                                      <span tooltip="Cost of each additional workflow execution, and each conversation message, over the monthly limit. ">
                                        <InfoIcon />
                                      </span>
                                    </li>
                                    <li>
                                      <label />
                                      <b>Email & Help Doc</b> Support
                                    </li>
                                  </>
                                )
                              ) : (
                                <>
                                  <li>
                                    <label />
                                    Chatbot Builder
                                    <span tooltip="No-code chatbot builder tools to create and manage chatbot.">
                                      <InfoIcon />
                                    </span>
                                  </li>
                                  <li>
                                    <label />
                                    Chatbot Automation
                                    <span tooltip="No-code workflow builder to create app based workflow automations for chatbot.">
                                      <InfoIcon />
                                    </span>
                                  </li>
                                  <li>
                                    <label />
                                    App Integrations
                                    <span tooltip="Pre-built app integrations or connectors for chatbot.">
                                      <InfoIcon />
                                    </span>
                                  </li>
                                  <li>
                                    <label />
                                    Workflow Marketplace
                                    <span tooltip="Pre-built app based workflows for chatbot.">
                                      <InfoIcon />
                                    </span>
                                  </li>
                                  <li>
                                    <label />
                                    Teams and Slack Integration
                                    <span tooltip="Deploy chatbot on your Slack and Teams.">
                                      <InfoIcon />
                                    </span>
                                  </li>

                                  <li>
                                    <label />
                                    <b>2 </b> Active Workflows
                                    <span tooltip="Total active workflow automations to use within chatbot. Admin can disable old and enable new workflows.">
                                      <InfoIcon />
                                    </span>
                                  </li>

                                  <li>
                                    <label />
                                    <b>600</b> Workflow Executions
                                    <span tooltip="Total number of workflow executions per month. ">
                                      <InfoIcon />
                                    </span>
                                  </li>
                                  <li>
                                    <label />
                                    <b>3000</b> Conversation Messages
                                    <span tooltip="Total number of conversation messages per month.">
                                      <InfoIcon />
                                    </span>
                                  </li>
                                  <li>
                                    <label />
                                    <b>1 </b>Admin
                                    <span tooltip="Number of admins access.">
                                      <InfoIcon />
                                    </span>
                                  </li>

                                  <li>
                                    <label />
                                    <b>$0.01</b> Additional Consumptions
                                    <span tooltip="Cost of each additional workflow execution, and each conversation message, over the monthly limit. ">
                                      <InfoIcon />
                                    </span>
                                  </li>
                                  <li>
                                    <label />
                                    <b>Email & Help Doc</b> Support
                                  </li>
                                </>
                              )}
                            </ul>
                            {isMobile ? (
                              <div
                                className={
                                  title
                                    ? "view_details _open"
                                    : "view_details _close"
                                }
                                onClick={() => {
                                  setMore(!more);
                                  setTitle(!title);
                                }}
                              >
                                {title ? "View Details" : "Hide Details"}
                                <div>
                                  <DropdownBlack />
                                </div>
                              </div>
                            ) : null}
                          </div>
                          <div className="plan">
                            <div className="paln_svg_center">
                              <PricestadardIcon />
                            </div>
                            <h4>Standard</h4>
                            <span className="users_upto">
                              (Suitable for 600 users or less)
                            </span>
                            <a
                              plan={plan}
                              onClick={() => setPlan("standard")}
                              className="button sign-up url_manipulation"
                              href="javascript:void(0);"
                            >
                              Get Free Trial
                            </a>
                            <ul>
                            {isMobile ? (
                                !more1 ? (
                                  <>
                              <li>
                                <label />
                                Chatbot Builder
                                <span tooltip="No-code chatbot builder tools to create and manage chatbot.">
                                  <InfoIcon />
                                </span>
                              </li>
                              <li>
                                <label />
                                Chatbot Automation
                                <span tooltip="No-code workflow builder to create app based workflow automations for chatbot.">
                                  <InfoIcon />
                                </span>
                              </li>
                              <li>
                                <label />
                                App Integrations
                                <span tooltip="Pre-built app integrations or connectors for chatbot.">
                                  <InfoIcon />
                                </span>
                              </li>
                              </>
                              ) : (
                              <>
                              <li>
                                <label />
                                Chatbot Builder
                                <span tooltip="No-code chatbot builder tools to create and manage chatbot.">
                                  <InfoIcon />
                                </span>
                              </li>
                              <li>
                                <label />
                                Chatbot Automation
                                <span tooltip="No-code workflow builder to create app based workflow automations for chatbot.">
                                  <InfoIcon />
                                </span>
                              </li>
                              <li>
                                <label />
                                App Integrations
                                <span tooltip="Pre-built app integrations or connectors for chatbot.">
                                  <InfoIcon />
                                </span>
                              </li>
                              <li>
                                <label />
                                Workflow Marketplace
                                <span tooltip="Pre-built app based workflows for chatbot.">
                                  <InfoIcon />
                                </span>
                              </li>
                              <li>
                                <label />
                                Teams and Slack Integration
                                <span tooltip="Deploy chatbot on your Slack and Teams.">
                                  <InfoIcon />
                                </span>
                              </li>

                              <li>
                                <label />
                                Multiple Chatbots
                                <span tooltip="Supports multiple chatbot creation.">
                                  <InfoIcon />
                                </span>
                              </li>

                              <li>
                                <label />
                                <b>5 </b> Active Workflows
                                <span tooltip="Total active workflow automations to use within chatbot. Admin can disable old and enable new workflows.">
                                  <InfoIcon />
                                </span>
                              </li>

                              <li>
                                <label />
                                <b>1500</b> Workflow Executions
                                <span tooltip="Total number of workflow executions per month. ">
                                  <InfoIcon />
                                </span>
                              </li>
                              <li>
                                <label />
                                <b>7000</b> Conversation Messages
                                <span tooltip="Total number of conversation messages per month.">
                                  <InfoIcon />
                                </span>
                              </li>
                              <li>
                                <label />
                                <b>2 </b>Admins
                                <span tooltip="Number of admins access.">
                                  <InfoIcon />
                                </span>
                              </li>

                              <li>
                                <label />
                                <b>$0.01</b> Additional Consumptions
                                <span tooltip="Cost of each additional workflow execution, and each conversation message, over the monthly limit. ">
                                  <InfoIcon />
                                </span>
                              </li>
                              <li>
                                <label />
                                <b>Email, Help Doc, and Phone</b> Support
                                <span tooltip="Phone support is outbound calls only. ">
                                  <InfoIcon />
                                </span>
                              </li>
                              </>
                            )
                            ) : (
                              <>
                              <li>
                                <label />
                                Chatbot Builder
                                <span tooltip="No-code chatbot builder tools to create and manage chatbot.">
                                  <InfoIcon />
                                </span>
                              </li>
                              <li>
                                <label />
                                Chatbot Automation
                                <span tooltip="No-code workflow builder to create app based workflow automations for chatbot.">
                                  <InfoIcon />
                                </span>
                              </li>
                              <li>
                                <label />
                                App Integrations
                                <span tooltip="Pre-built app integrations or connectors for chatbot.">
                                  <InfoIcon />
                                </span>
                              </li>
                              <li>
                                <label />
                                Workflow Marketplace
                                <span tooltip="Pre-built app based workflows for chatbot.">
                                  <InfoIcon />
                                </span>
                              </li>
                              <li>
                                <label />
                                Teams and Slack Integration
                                <span tooltip="Deploy chatbot on your Slack and Teams.">
                                  <InfoIcon />
                                </span>
                              </li>

                              <li>
                                <label />
                                Multiple Chatbots
                                <span tooltip="Supports multiple chatbot creation.">
                                  <InfoIcon />
                                </span>
                              </li>

                              <li>
                                <label />
                                <b>5 </b> Active Workflows
                                <span tooltip="Total active workflow automations to use within chatbot. Admin can disable old and enable new workflows.">
                                  <InfoIcon />
                                </span>
                              </li>

                              <li>
                                <label />
                                <b>1500</b> Workflow Executions
                                <span tooltip="Total number of workflow executions per month. ">
                                  <InfoIcon />
                                </span>
                              </li>
                              <li>
                                <label />
                                <b>7000</b> Conversation Messages
                                <span tooltip="Total number of conversation messages per month.">
                                  <InfoIcon />
                                </span>
                              </li>
                              <li>
                                <label />
                                <b>2 </b>Admins
                                <span tooltip="Number of admins access.">
                                  <InfoIcon />
                                </span>
                              </li>

                              <li>
                                <label />
                                <b>$0.01</b> Additional Consumptions
                                <span tooltip="Cost of each additional workflow execution, and each conversation message, over the monthly limit. ">
                                  <InfoIcon />
                                </span>
                              </li>
                              <li>
                                <label />
                                <b>Email, Help Doc, and Phone</b> Support
                                <span tooltip="Phone support is outbound calls only. ">
                                  <InfoIcon />
                                </span>
                              </li>
                              </>
                        
                            )}
                            </ul>
                            {isMobile ? (
                              <div
                                className={
                                  title
                                    ? "view_details _open"
                                    : "view_details _close"
                                }
                                onClick={() => {
                                  setMore1(!more1);
                                  setTitle(!title);
                                }}
                              >
                                {title ? "View Details" : "Hide Details"}
                                <div>
                                  <DropdownBlack />
                                </div>
                              </div>
                            ) : null}
                          </div>
                          
                          
                          
                          <div className="plan">
                          <div className="paln_svg_center">
                              <div className="paln_svg_center">
                                <PriceProIcon />
                              </div>
                            </div>
                            <h4>Pro</h4>
                            <span className="users_upto">
                              (Suitable for 1000 users or less)
                            </span>
                            <a
                              plan={plan}
                              onClick={() => setPlan("pro")}
                              className="button sign-up url_manipulation"
                              href="javascript:void(0);"
                            >
                              Get Free Trial
                            </a>
                            <ul>
                            {isMobile ? (
                                !more2 ? (
                                  <>
                               <li>
                                <label />
                                Chatbot Builder
                                <span tooltip="No-code chatbot builder tools to create and manage chatbot.">
                                  <InfoIcon />
                                </span>
                              </li>
                              <li>
                                <label />
                                Chatbot Automation
                                <span tooltip="No-code workflow builder to create app based workflow automations for chatbot.">
                                  <InfoIcon />
                                </span>
                              </li>
                              <li>
                                <label />
                                App Integrations
                                <span tooltip="Pre-built app integrations or connectors for chatbot.">
                                  <InfoIcon />
                                </span>
                              </li>
                              </>
                              ) : (
                              <>
                              <li>
                                <label />
                                Chatbot Builder
                                <span tooltip="No-code chatbot builder tools to create and manage chatbot.">
                                  <InfoIcon />
                                </span>
                              </li>
                              <li>
                                <label />
                                Chatbot Automation
                                <span tooltip="No-code workflow builder to create app based workflow automations for chatbot.">
                                  <InfoIcon />
                                </span>
                              </li>
                              <li>
                                <label />
                                App Integrations
                                <span tooltip="Pre-built app integrations or connectors for chatbot.">
                                  <InfoIcon />
                                </span>
                              </li>
                              <li>
                                <label />
                                Workflow Marketplace
                                <span tooltip="Pre-built app based workflows for chatbot.">
                                  <InfoIcon />
                                </span>
                              </li>
                              <li>
                                <label />
                                Teams and Slack Integration
                                <span tooltip="Deploy chatbot on your Slack and Teams.">
                                  <InfoIcon />
                                </span>
                              </li>
                              <li>
                                <label />
                                On-prem Connect
                                <span tooltip="Connect chatbot with on-prem applications.">
                                  <InfoIcon />
                                </span>
                              </li>
                              <li>
                                <label />
                                Multiple Chatbots
                                <span tooltip="Supports multiple chatbot creation.">
                                  <InfoIcon />
                                </span>
                              </li>

                              <li>
                                <label />
                                <b>8 </b> Active Workflows
                                <span tooltip="Total active workflow automations to use within chatbot. Admin can disable old and enable new workflows.">
                                  <InfoIcon />
                                </span>
                              </li>

                              <li>
                                <label />
                                <b>3000</b> Workflow Executions
                                <span tooltip="Total number of workflow executions per month. ">
                                  <InfoIcon />
                                </span>
                              </li>
                              <li>
                                <label />
                                <b>12000</b> Conversation Messages
                                <span tooltip="Total number of conversation messages per month.">
                                  <InfoIcon />
                                </span>
                              </li>
                              <li>
                                <label />
                                <b>3 </b>Admins
                                <span tooltip="Number of admins access.">
                                  <InfoIcon />
                                </span>
                              </li>

                              <li>
                                <label />
                                <b>$0.01</b> Additional Consumptions
                                <span tooltip="Cost of each additional workflow execution, and each conversation message, over the monthly limit. ">
                                  <InfoIcon />
                                </span>
                              </li>
                              <li>
                                <label />
                                <b>Email, Help Doc, and Phone</b> Support
                                <span tooltip="Phone support is outbound calls only. ">
                                  <InfoIcon />
                                </span>
                              </li>
                              </>
                            )
                            ) : (
                              <>
                              <li>
                                <label />
                                Chatbot Builder
                                <span tooltip="No-code chatbot builder tools to create and manage chatbot.">
                                  <InfoIcon />
                                </span>
                              </li>
                              <li>
                                <label />
                                Chatbot Automation
                                <span tooltip="No-code workflow builder to create app based workflow automations for chatbot.">
                                  <InfoIcon />
                                </span>
                              </li>
                              <li>
                                <label />
                                App Integrations
                                <span tooltip="Pre-built app integrations or connectors for chatbot.">
                                  <InfoIcon />
                                </span>
                              </li>
                              <li>
                                <label />
                                Workflow Marketplace
                                <span tooltip="Pre-built app based workflows for chatbot.">
                                  <InfoIcon />
                                </span>
                              </li>
                              <li>
                                <label />
                                Teams and Slack Integration
                                <span tooltip="Deploy chatbot on your Slack and Teams.">
                                  <InfoIcon />
                                </span>
                              </li>
                              <li>
                                <label />
                                On-prem Connect
                                <span tooltip="Connect chatbot with on-prem applications.">
                                  <InfoIcon />
                                </span>
                              </li>
                              <li>
                                <label />
                                Multiple Chatbots
                                <span tooltip="Supports multiple chatbot creation.">
                                  <InfoIcon />
                                </span>
                              </li>

                              <li>
                                <label />
                                <b>8 </b> Active Workflows
                                <span tooltip="Total active workflow automations to use within chatbot. Admin can disable old and enable new workflows.">
                                  <InfoIcon />
                                </span>
                              </li>

                              <li>
                                <label />
                                <b>3000</b> Workflow Executions
                                <span tooltip="Total number of workflow executions per month. ">
                                  <InfoIcon />
                                </span>
                              </li>
                              <li>
                                <label />
                                <b>12000</b> Conversation Messages
                                <span tooltip="Total number of conversation messages per month.">
                                  <InfoIcon />
                                </span>
                              </li>
                              <li>
                                <label />
                                <b>3 </b>Admins
                                <span tooltip="Number of admins access.">
                                  <InfoIcon />
                                </span>
                              </li>

                              <li>
                                <label />
                                <b>$0.01</b> Additional Consumptions
                                <span tooltip="Cost of each additional workflow execution, and each conversation message, over the monthly limit. ">
                                  <InfoIcon />
                                </span>
                              </li>
                              <li>
                                <label />
                                <b>Email, Help Doc, and Phone</b> Support
                                <span tooltip="Phone support is outbound calls only. ">
                                  <InfoIcon />
                                </span>
                              </li>
                              </>
                            )}
                            </ul>
                            {isMobile ? (
                              <div
                                className={
                                  title
                                    ? "view_details _open"
                                    : "view_details _close"
                                }
                                onClick={() => {
                                  setMore2(!more2);
                                  setTitle(!title);
                                }}
                              >
                                {title ? "View Details" : "Hide Details"}
                                <div>
                                  <DropdownBlack />
                                </div>
                              </div>
                            ) : null}
                          </div>
                     
                          
                          <div className="plan plan_nterprise">
                            <div className="paln_svg_center">
                              <PriceEnterpriseIcon />
                            </div>
                            <h4>Enterprise</h4>
                            <span className="users_upto">Enterprise</span>
                            <a
                              plan={plan}
                              onClick={() => setPlan("enterprise")}
                              className="button sign-up url_manipulation"
                              href="javascript:void(0);"
                            >
                              Contact Sales
                            </a>
                            <ul>
                              {isMobile ? (
                              <p className="enterprise_message">
                                  Looking for More.<br></br>
                                   Enterprise plans are customized. <br></br>
                                   please reach out to us at<br></br>
                                  <span>
                                    <a href="mailto:sales@workativ.com">
                                      sales@workativ.com
                                    </a>
                                    </span>
                                    <br></br>
                                    and we’d be happy to discuss.
                                  </p>
                                
                              ) : (
                                <p className="enterprise_message">
                                  Looking for More. <br></br>
                                  Enterprise plans are customized. please reach
                                  out to us at{" "}
                                  <span>
                                    <a href="mailto:sales@workativ.com">
                                      sales@workativ.com
                                    </a>
                                  </span>{" "}
                                  and we'd be happy to discuss.
                                </p>
                              )}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="yearly">
                      <div className="pricing-chart">
                        <div className="smaller-plans">
                          <div className="plan">
                            <PriceFreeIcon /> <h4>Basic</h4>
                            <ul>
                              <li>
                                <label />
                                Chatbot Builder
                                <span tooltip="Build dialogs for assistant.">
                                  <InfoIcon />
                                </span>
                              </li>
                              <li>
                                <label />
                                Chatbot Automation
                                <span tooltip="Build & configure automations/workflows actions for Apps.">
                                  <InfoIcon />
                                </span>
                              </li>
                              <li>
                                <label />
                                App Integrations
                                <span tooltip="Deploy bot on Slack and Microsoft Teams.">
                                  <InfoIcon />
                                </span>
                              </li>
                              <li>
                                <label />
                                Workflow Marketplace
                                <span tooltip="Connect with On-Prem applications.">
                                  <InfoIcon />
                                </span>
                              </li>
                              <li>
                                <label />
                                Multiple Bots
                                <span tooltip="Supports multiple bot creation.">
                                  <InfoIcon />
                                </span>
                              </li>
                              <li>
                                <label />
                                <b>3 </b> Active Automations
                                <span tooltip="Total active automations to use within bot. Admin can disable old and enable new automations.">
                                  <InfoIcon />
                                </span>
                              </li>

                              <li>
                                <label />
                                <b>1000</b> Automation executions
                                <span tooltip="Total number of automation executions per month. ">
                                  <InfoIcon />
                                </span>
                              </li>
                              <li>
                                <label />
                                <b>3000</b> Conversation messages
                                <span tooltip="Total number of conversation messages per month.">
                                  <InfoIcon />
                                </span>
                              </li>
                              <li>
                                <label />
                                <b>1 </b>Admins
                                <span tooltip="Number of admins access.">
                                  <InfoIcon />
                                </span>
                              </li>

                              <li>
                                <label />
                                <b>Add-On Consumptions</b> for 0.01 $
                                <span tooltip="Cost of each additional workflow execution, and each conversation message, over the monthly limit. ">
                                  <InfoIcon />
                                </span>
                              </li>
                              <li>
                                <label />
                                <b>Email, Help Doc, and Phone</b> Support
                                <span tooltip="Phone support is outbound calls only. ">
                                  <InfoIcon />
                                </span>
                              </li>
                            </ul>
                            <a
                              className="button sign-up url_manipulation"
                              href="/conversational-ai-platform/demo"
                            >
                              Sign up
                            </a>
                          </div>
                          <div className="plan">
                            <PricestadardIcon /> <h4>Standard</h4>
                            <ul>
                              <li>
                                <label />
                                Chatbot Builder
                                <span tooltip="Build dialogs for assistant.">
                                  <InfoIcon />
                                </span>
                              </li>
                              <li>
                                <label />
                                Chatbot Automation
                                <span tooltip="Build & configure automations/workflows actions for Apps.">
                                  <InfoIcon />
                                </span>
                              </li>
                              <li>
                                <label />
                                App Integrations
                                <span tooltip="Deploy bot on Slack and Microsoft Teams.">
                                  <InfoIcon />
                                </span>
                              </li>
                              <li>
                                <label />
                                Workflow Marketplace
                                <span tooltip="Connect with On-Prem applications.">
                                  <InfoIcon />
                                </span>
                              </li>
                              <li>
                                <label />
                                Multiple Bots
                                <span tooltip="Supports multiple bot creation.">
                                  <InfoIcon />
                                </span>
                              </li>
                              <li>
                                <label />
                                <b>6 </b> Active Automations
                                <span tooltip="Total active automations to use within bot. Admin can disable old and enable new automations.">
                                  <InfoIcon />
                                </span>
                              </li>

                              <li>
                                <label />
                                <b>2000</b> Automation executions
                                <span tooltip="Total number of automation executions per month. ">
                                  <InfoIcon />
                                </span>
                              </li>
                              <li>
                                <label />
                                <b>7000</b> Conversation messages
                                <span tooltip="Total number of conversation messages per month.">
                                  <InfoIcon />
                                </span>
                              </li>
                              <li>
                                <label />
                                <b>2 </b>Admins
                                <span tooltip="Number of admins access.">
                                  <InfoIcon />
                                </span>
                              </li>

                              <li>
                                <label />
                                <b>Add-On Consumptions</b> for 0.01 $
                                <span tooltip="Cost of each additional workflow execution, and each conversation message, over the monthly limit. ">
                                  <InfoIcon />
                                </span>
                              </li>
                              <li>
                                <label />
                                <b>Email, Help Doc, and Phone</b> Support
                                <span tooltip="Phone support is outbound calls only. ">
                                  <InfoIcon />
                                </span>
                              </li>
                            </ul>
                            <a
                              className="button sign-up url_manipulation"
                              href="/conversational-ai-platform/demo"
                            >
                              Sign up
                            </a>
                          </div>
                          <div className="plan">
                            <PriceProIcon />
                            <h4>Pro</h4>

                            <ul>
                              <li>
                                <label />
                                Chatbot Builder
                                <span tooltip="Build dialogs for assistant.">
                                  <InfoIcon />
                                </span>
                              </li>
                              <li>
                                <label />
                                Chatbot Automation
                                <span tooltip="Build & configure automations/workflows actions for Apps.">
                                  <InfoIcon />
                                </span>
                              </li>
                              <li>
                                <label />
                                App Integrations
                                <span tooltip="Deploy bot on Slack and Microsoft Teams.">
                                  <InfoIcon />
                                </span>
                              </li>
                              <li>
                                <label />
                                Workflow Marketplace
                                <span tooltip="Connect with On-Prem applications.">
                                  <InfoIcon />
                                </span>
                              </li>
                              <li>
                                <label />
                                Multiple Bots
                                <span tooltip="Supports multiple bot creation.">
                                  <InfoIcon />
                                </span>
                              </li>
                              <li>
                                <label />
                                <b>10 </b> Active Automations
                                <span tooltip="Total active automations to use within bot. Admin can disable old and enable new automations.">
                                  <InfoIcon />
                                </span>
                              </li>

                              <li>
                                <label />
                                <b>5000</b> Automation executions
                                <span tooltip="Total number of automation executions per month. ">
                                  <InfoIcon />
                                </span>
                              </li>
                              <li>
                                <label />
                                <b>15000</b> Conversation messages
                                <span tooltip="Total number of conversation messages per month.">
                                  <InfoIcon />
                                </span>
                              </li>

                              <li>
                                <label />
                                <b>3</b> Admins
                                <span tooltip="Number of admins access. ">
                                  <InfoIcon />
                                </span>
                              </li>

                              <li>
                                <label />
                                <b>Add-On Consumptions</b> for 0.01 $
                                <span tooltip="Cost of each additional workflow execution, and each conversation message, over the monthly limit. ">
                                  <InfoIcon />
                                </span>
                              </li>
                              <li>
                                <label />
                                <b>Email & Help Doc</b> Support
                                <span tooltip="Phone support is outbound calls only. ">
                                  <InfoIcon />
                                </span>
                              </li>
                            </ul>

                            <a
                              className="button sign-up url_manipulation"
                              href="/conversational-ai-platform/demo"
                            >
                              Sign up
                            </a>
                          </div>
                          <div className="plan plan_nterprise">
                            <h4>Enterprise</h4>
                            <PriceEnterpriseIcon />
                            <ul>
                              <p className="enterprise_message">
                                Looking for More. <br></br>
                                Enterprise plans are customized. please reach
                                out to us at{" "}
                                <span>
                                  <a href="mailto:sales@workativ.com">
                                    sales@workativ.com
                                  </a>
                                </span>{" "}
                                and we'd be happy to discuss.
                              </p>
                            </ul>

                            <a
                              className="button sign-up url_manipulation"
                              href="/conversational-ai-platform/demo"
                            >
                              Contact Sales
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </section>

            {/* Full comparision */}
            <section className="full_comaparision">
              <div className="container">
                <h1>All prices are exclusive of applicable taxes </h1>
                <p>24 x 7 email support included with all plans</p>
                <div
                  className={
                    open
                      ? "show_button_full_comaparison open"
                      : "show_button_full_comaparison "
                  }
                >
                  {isMobile ? null :
                  <button
                    className="btn btn-primary"
                    onClick={() => {
                      setOpen(!open);
                      setTitle(!title);
                    }}
                  >
                    {" "}
                    {title ? "Show full comparison" : "Hide full comparison"}
                  </button>
              }
                </div>
                <div className="table_mob_comapre">
                <div className="table_full_comapare">
                <div className="mob_overflow_table">
                  <table className="table table-bordered table-responsive-sm">
                    <thead>
                      <tr className="tr_bg">
                        <th scope="col">Features</th>
                        <th scope="col">Description</th>
                        <th scope="col">Basic</th>
                        <th scope="col">Standard</th>
                        <th scope="col">Pro</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="tr_bg">
                        <th scope="col">Chatbot Builder </th>
                        <th scope="col" />
                        <th scope="col" />
                        <th scope="col" />
                        <th scope="col" />
                      </tr>
                      <tr>
                        <td>Canvas</td>
                        <td>
                          Visual no-code chatbot builder canvas tools to create
                          and manage dialogs.
                        </td>
                        <td>
                          <YesIcon />
                        </td>
                        <td>
                          <YesIcon />
                        </td>
                        <td>
                          <YesIcon />
                        </td>
                      </tr>
                      <tr>
                        <td>Intents</td>
                        <td>
                          Smart Intent recognizer to identify user intentions.
                        </td>
                        <td>
                          <YesIcon />
                        </td>
                        <td>
                          <YesIcon />
                        </td>
                        <td>
                          <YesIcon />
                        </td>
                      </tr>
                      <tr>
                        <td>Entities</td>
                        <td>
                          Smart Entity recognizer to validate user inputs.
                        </td>
                        <td>
                          <YesIcon />
                        </td>
                        <td>
                          <YesIcon />
                        </td>
                        <td>
                          <YesIcon />
                        </td>
                      </tr>
                      <tr>
                        <td>Feedback</td>
                        <td>Collect conversation feedback from end users.</td>
                        <td>
                          <YesIcon />
                        </td>
                        <td>
                          <YesIcon />
                        </td>
                        <td>
                          <YesIcon />
                        </td>
                      </tr>
                      <tr>
                        <td>Try Me</td>
                        <td>
                          Try me helps admin test dialogs or conversations.
                        </td>
                        <td>
                          <YesIcon />
                        </td>
                        <td>
                          <YesIcon />
                        </td>
                        <td>
                          <YesIcon />
                        </td>
                      </tr>
                      <tr>
                        <td>Workflow Orchestrator</td>
                        <td>
                          Connect and execute app based workflows within dialogs
                          in a single click.
                        </td>
                        <td>
                          <YesIcon />
                        </td>
                        <td>
                          <YesIcon />
                        </td>
                        <td>
                          <YesIcon />
                        </td>
                      </tr>
                      <tr className="tr_bg">
                        <th scope="col">Chatbot Automation </th>
                        <th scope="col" />
                        <th scope="col" />
                        <th scope="col" />
                        <th scope="col" />
                      </tr>
                      <tr>
                        <td>Canvas</td>
                        <td>
                          No-code workflow builder to create app based workflow
                          automation for chatbot.
                        </td>
                        <td>
                          <YesIcon />
                        </td>
                        <td>
                          <YesIcon />
                        </td>
                        <td>
                          <YesIcon />
                        </td>
                      </tr>
                      <tr>
                        <td>Testing</td>
                        <td>Test your workflows.</td>
                        <td>
                          <YesIcon />
                        </td>
                        <td>
                          <YesIcon />
                        </td>
                        <td>
                          <YesIcon />
                        </td>
                      </tr>
                      <tr>
                        <td>Workflow Executor</td>
                        <td>
                          Workflow automations executed by chatbot based on end
                          user request.
                        </td>
                        <td>
                          <YesIcon />
                        </td>
                        <td>
                          <YesIcon />
                        </td>
                        <td>
                          <YesIcon />
                        </td>
                      </tr>
                      <tr>
                        <td>One-click App Integrations</td>
                        <td>Pre-built app integrations or connectors.</td>
                        <td>
                          <YesIcon />
                        </td>
                        <td>
                          <YesIcon />
                        </td>
                        <td>
                          <YesIcon />
                        </td>
                      </tr>
                      <tr>
                        <td>On-prem Connect</td>
                        <td>Connect chatbot with your on-prem applications.</td>
                        <td>
                          <NoIcon />
                        </td>
                        <td>
                          <NoIcon />
                        </td>
                        <td>
                          <YesIcon />
                        </td>
                      </tr>
                      <tr className="tr_bg">
                        <th scope="col">Marketplace</th>
                        <th scope="col" />
                        <th scope="col" />
                        <th scope="col" />
                        <th scope="col" />
                      </tr>
                      <tr>
                        <td>Pre-built App Workflows</td>
                        <td>
                          Download pre-built workflow automation recipes for
                          apps from marketplace, to use within chatbot.
                        </td>
                        <td>
                          <YesIcon />
                        </td>
                        <td>
                          <YesIcon />
                        </td>
                        <td>
                          <YesIcon />
                        </td>
                      </tr>
                      <tr className="tr_bg">
                        <th scope="col">Chatbot Channels</th>
                        <th scope="col" />
                        <th scope="col" />
                        <th scope="col" />
                        <th scope="col" />
                      </tr>
                      <tr>
                        <td>Slack and Teams Integration</td>
                        <td>
                          Deploy chatbot on your Slack and or Teams channels.
                        </td>
                        <td>
                          <YesIcon />
                        </td>
                        <td>
                          <YesIcon />
                        </td>
                        <td>
                          <YesIcon />
                        </td>
                      </tr>

                      <tr className="tr_bg">
                        <th scope="col">Training</th>
                        <th scope="col" />
                        <th scope="col" />
                        <th scope="col" />
                        <th scope="col" />
                      </tr>
                      <tr>
                        <td>Assistant Training</td>
                        <td>
                          Train chatbot using chat conversations to improve
                          chatbot performance.
                        </td>
                        <td>
                          <YesIcon />
                        </td>
                        <td>
                          <YesIcon />
                        </td>
                        <td>
                          <YesIcon />
                        </td>
                      </tr>
                      <tr>
                        <td>Training Data </td>
                        <td>Chat conversations available for training.</td>
                        <td>14 days</td>
                        <td>14 days</td>
                        <td>30 days</td>
                      </tr>

                      <tr className="tr_bg">
                        <th scope="col">Analytics</th>
                        <th scope="col" />
                        <th scope="col" />
                        <th scope="col" />
                        <th scope="col" />
                      </tr>

                      <tr>
                        <td>Conversation Analytics</td>
                        <td>Analyze chatbot performance.</td>
                        <td>
                          <YesIcon />
                        </td>
                        <td>
                          <YesIcon />
                        </td>
                        <td>
                          <YesIcon />
                        </td>
                      </tr>
                      <tr>
                        <td>Workflow Analytics</td>
                        <td>Analyze workflow execution performance.</td>
                        <td>
                          <YesIcon />
                        </td>
                        <td>
                          <YesIcon />
                        </td>
                        <td>
                          <YesIcon />
                        </td>
                      </tr>
                      <tr>
                        <td>Data availability</td>
                        <td>Data available for analysis.</td>
                        <td>14 days</td>
                        <td>14 days</td>
                        <td>30 days</td>
                      </tr>

                      <tr className="tr_bg">
                        <th scope="col">Logs</th>
                        <th scope="col" />
                        <th scope="col" />
                        <th scope="col" />
                        <th scope="col" />
                      </tr>

                      <tr>
                        <td>Conversation Logs</td>
                        <td>
                          Conversations logs between chatbot and end user.
                        </td>
                        <td>
                          <YesIcon />
                        </td>
                        <td>
                          <YesIcon />
                        </td>
                        <td>
                          <YesIcon />
                        </td>
                      </tr>
                      <tr>
                        <td>Workflow Logs</td>
                        <td>
                          Workflow automation logs to verify success or failed
                          executions.
                        </td>
                        <td>
                          <YesIcon />
                        </td>
                        <td>
                          <YesIcon />
                        </td>
                        <td>
                          <YesIcon />
                        </td>
                      </tr>
                      <tr>
                        <td>Data availability</td>
                        <td>Logs available for analysis.</td>
                        <td>14 days</td>
                        <td>14 days</td>
                        <td>30 days</td>
                      </tr>

                      <tr className="tr_bg">
                        <th scope="col">App Notifications</th>
                        <th scope="col" />
                        <th scope="col" />
                        <th scope="col" />
                        <th scope="col" />
                      </tr>
                      <tr>
                        <td>Admin Notifications</td>
                        <td>Product notifications via email and within app.</td>
                        <td>
                          <YesIcon />
                        </td>
                        <td>
                          <YesIcon />
                        </td>
                        <td>
                          <YesIcon />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                </div>
                <div className="bud_space"></div>
                </div>
              {isMobile ?
              <div className="show_button_full_comaparison">
                <button
                    className="btn btn-primary"
                    onClick={() => {
                      setOpen(!open);
                      setTitle(!title);
                    }}
                  >
                    {" "}
                    {title ? "Show full comparison" : "Hide full comparison"}
                  </button>
                  </div>
                  : null}
              </div>
            </section>
            {/*Pricing End*/}
          </div>
          <section className="top_section top_section_bottom pricing_page">
            <div className="container-fluid">
              <div className="row">
                <div className="container">
                  <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div className="main-slider-left">
                      <h1>Professional Services</h1>
                      <h6>
                        Need help on building your first chatbot? or need hand
                        with additional training?
                      </h6>
                      <p>
                        Our professional services can help you design, build,
                        configure and optimize your chatbot using our platform.
                        We also offer training to help you do it yourself. Reach
                        out to us to discuss your requirement.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <Faq />
          <RequestForm isFooterForm={true} />
          {console.log(plan,"plan")}
          {plan == "" ? null : (
            <RequestFormModal
              setPlan={setPlan}
              plan={plan}
              showThanksPage={showThanksPage}
              setShowThanksPage={setShowThanksPage}
            />
          )}
        </Layout>
      </Container>
    </>
  );
}

function Faq() {
  return (
    <React.Fragment>
      <div className="mob_accordian mob_accordian_faq">
        <section className="accordian_landing trial_accordian_landing">
          <div className="container">
            <h2>Pricing Frequently Asked Questions</h2>
            <div className="col-12 pl-0 faq_landing">
              <Accordion
                id="accordion"
                className="accordion pl-0"
                preExpanded={["a"]}
              >
                <div className="accordion-wrapper pricing_faq">
                  {faqData.map((data) => (
                    <AccordionItem uuid={data.uuid}>
                      <AccordionItemHeading>
                        <AccordionItemButton>{data.header}</AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p
                          dangerouslySetInnerHTML={{ __html: data.paragraph }}
                        />
                        {data.paragraph1 && (
                          <p
                            className="para_2_pricing"
                            dangerouslySetInnerHTML={{
                              __html: data.paragraph1,
                            }}
                          />
                        )}
                      </AccordionItemPanel>
                    </AccordionItem>
                  ))}
                </div>
              </Accordion>
            </div>
          </div>
        </section>
      </div>
    </React.Fragment>
  );
}

export const faqData = [
  {
    header: "How do we calculate conversation messages? ",
    paragraph:
      "A conversation message is counted as 1 when an end-user sends a message to the Chabot. Please note that we do not count the number of responses from chatbot to end user. ",
    active: "active",
    uuid: "a",
  },
  {
    header: "What is workflow execution? ",
    paragraph:
      "Workflow execution happens when the chatbot executes a task or action(s) within an app. You can have multiple actions in a single workflow execution.",
  },
  {
    header: "How do we calculate workflow executions? ",
    paragraph:
      "A workflow execution is calculated as 1 execution, irrespective of number of actions within the workflow. For example, when chatbot unlocks a user account in Active Directory and creates a closed ticket in ServiceNow. In this workflow you have used 2 actions, but we only calculate as 1 workflow execution. If you execute the workflow 10 times, 10 workflow executions will be consumed from your available execution limit or quota depending on your subscription plan.",
  },
  {
    header: "What are additional consumptions? ",
    paragraph:
      "Each subscription plan comes with a chatbot conversational message and workflow execution quota or limit. Once you exceeds this quota or limit, you will be charged $0.01 for each additional message, and each workflow execution.",
  },
  {
    header: "What is on-prem connector? ",
    paragraph:
      "Workativ’s On-prem Connector provides a secure connection between our platform (and chatbot) and your on-prem apps behind your corporate firewall. For increased level of security, workativ relays HTTPS calls to workativ agent behind your corporate firewall to execute workflows with your on-prem apps.",
  },
  {
    header: "How safe is my data?",
    paragraph:
      'We take security seriously. Our servers are hosted in world-class data centers in AWS that are protected by biometric locks and 24-hour surveillance. We ensure that our application and servers are always up to date with the latest security patches. All plans include SSL for encryption to keep your data safe. You can find more on our security measure <span><a href="/security">here</a></span>.',
  },
  {
    header: "Plan selection. How do I know which plan is right for me?",
    paragraph:
      "To help you decide which plan is right, we suggest you consider the following: 1. Employee count. 2. Ticket volume.",
    paragraph1: `Though we do not charge by user count, we suggest that you keep user concurrency in mind. The Basic plan is ideal for an employee strength in the range of 250-300, Standard plan is ideal for users in the range of 600 or less, and Pro plan in the range of 1000 or less. And secondly, higher ticket volume may require more active automations. We can help you decide based on your needs, so just send us an email at <span><a href="mailto:sales@workativ.com">sales@workativ.com</a></span>.`,
  },
  {
    header: "Can I change my plan anytime? ",

    paragraph: "For now, you can switch to higher plans only. ",
  },
  {
    header: "Payment options?  ",

    paragraph:
      'We accept account transfer, wire transfer, and all major credit card payments. For more details, please contact <span><a href="mailto:sales@workativ.com">sales@workativ.com</a></span>',
  },
  {
    header: "Invoice and payments? ",

    paragraph:
      "Invoice is raised as soon as you sign-up and start using the app, post-trial period. Payments are due within 7 days of invoice. Additional usage of workflow executions and conversation messages are included in the next invoice.  ",
  },
  {
    header: "Languages supported",

    paragraph:
      "English, Arabic, Chinese(simpliﬁed), Czech, Dutch, French, German, Italian, Japanese, Korean, Portuguese (Brazilian), Spanish.",
  },
  {
    header: "More questions on plans and pricing?",

    paragraph:
      'If you have any additional questions or clarification on pricing or subscription, please reach out to us at <span><a href="mailto:sales@workativ.com">sales@workativ.com</a></span> and we’ll be happy to help.',
  },
];
